 <template>
  <!-- <div class="topic-select">
    <Panel title="主题精选" class="dark_bg">
      <div class="promotion_slider" v-for="value in topicList" :key="value.id">
        <P class="topic-cateimg" @click="goSecondClass(value.id, value.picUrl)">
          <img v-lazy="value.picUrl" alt />
        </P>
        <swiper :options="swiperOption" class="topic-goodsimg">
          <swiper-slide v-for="(item,i) in value.spuDTOList" >
            <router-link :to="`/goodsdetail/${item.id}`">
              <img v-lazy="item.img" alt />
              <p>{{ item.title }}</p>
              <p style="display: flex; margin: 0">
                <span
                  style="
                    font-size: 0.28rem;
                    color: #c93f3f;
                    margin-right: 0.06rem;
                  "
                  >￥{{ parseMoneyFormat(item.price/100) }}</span
                >
                <span
                  style="
                    color: #999;
                    font-size: 0.22rem;
                    text-decoration: line-through;
                  "
                  v-if="item.discountMoney"
                  >￥{{ parseMoneyFormat(item.minMoney/100) }}</span
                >
              </p>
              <p
                style="
                  display: flex;
                  flex-wrap: wrap;
                  justify-content: space-between;
                  margin: 0;
                "
              > -->
                <!-- <span
                  style="color: #999; font-size: 0.22rem; letter-spacing: 1px"
                  >已售{{ item.soldNum }}</span
                > -->
                <!-- <span
                  style="color:#999;font-size:.22rem;letter-spacing:1px;color:#c93f3f;"
                  v-if="item.score"
                >{{item.score}}积分</span> -->
              <!-- </p>
            </router-link>
          </swiper-slide>
        </swiper>
      </div>
    </Panel>
  </div> -->
    <div class="topic-select">
       
        <!-- <swiper :options="swiperOption" class="topic-goodsimg">
          <swiper-slide v-for="(value) in topicList" :key="value.id">
             <P class="topic-cateimg" @click="goSecondClass(value.id, value.picUrl)">
          <img style='width=100%' v-lazy="value.picUrl" alt />
        </P>
          </swiper-slide>
        </swiper> -->


        <van-swipe class="topic-goodsimg" :autoplay="3000" indicator-color="white">
        <van-swipe-item
          v-for="item in topicList"
          :key="item.id"
           @click="goSecondClass(item.id, item.picUrl,item.title)"
        >
          <van-image width="100%"  height="2.9rem" :src="item.picUrl" />
         
        </van-swipe-item>
      </van-swipe>
  </div>
 
</template>
 <!-- <div class="topic-select">
    <Panel title="主题精选" class="dark_bg">
      <div class="promotion_slider" v-for="value in topicList" :key="value.id">
        <P class="topic-cateimg" @click="goSecondClass(value.category.id)">
          <img v-lazy="value.category.imageURL" alt />
        </P>
        <swiper :options="swiperOption" class="topic-goodsimg">
          <swiper-slide v-for="item in value.content" :key="item.title">
            <router-link :to="`/goodsdetail/${item.id}`">
              <img v-lazy="item.imageURL" alt />
              <p>{{item.name}}</p>
              <p style="display:flex;margin:0;">
                <span
                  style="font-size:.28rem;color:#c93f3f;margin-right:.06rem"
                >￥{{item.moneyString}}</span>
                <span
                  style="color:#999;font-size:.22rem;text-decoration: line-through;"
                  v-if="item.discountMoney"
                >￥{{item.minMoney}}</span>
              </p>
              <p style="display:flex;flex-wrap:wrap;justify-content:space-between;margin:0;">
                <span style="color:#999;font-size:.22rem;letter-spacing:1px;">已售{{item.soldNum}}</span>
                <span
                  style="color:#999;font-size:.22rem;letter-spacing:1px;color:#c93f3f;"
                  v-if="item.score"
                >{{item.score}}积分</span>
              </p>
            </router-link>
          </swiper-slide>
        </swiper>
      </div>
    </Panel>
  </div> -->


<script>
import Panel from "@/components/common/panel";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import { Swipe, SwipeItem, Image } from "vant";

import "swiper/dist/css/swiper.css";
export default {
  data() {
    return {
      swiperOption: {
         direction: "horizontal" /* 横向滑动 */,
        loop: true,
        effect: "cards",
        // pagination: {
        //   el: ".swiper-pagination",
        // },
        speed: 400,
        observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true,
        autoplay: {
          disableOnInteraction: false,
          delay: 3500, //1秒切换一次
        },
      },
      topicList: [],
    };
  },
  components: {
    Panel,
    swiper,
    swiperSlide,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Image.name]: Image,
  },
  created() {
    this.getTopicList();
  },
  methods: {
    // 进入分类
    goSecondClass(categoryId, img ,title) {
      this.$router.push(
        {
          path: `/categoryImgGoods/${categoryId}?categoryName=${title}&categoryId=${categoryId}`,
          query: {
            iconUrl: img,
          },
        }
        // `/categoryImgGoods/${categoryId}?&iconUrl=${img}`
      );
      // this.$router.push(`/secondclass/${firstId}/goods/${secondId}`);
    },
    getTopicList() {
      this.$api.home.topicGoodsAddress().then((res) => {
        if (res.errno === 200) {
          this.topicList = res.data;
          // console.log(this.topicList);
        }
      });
    },
    // getTopicList() {
    //   this.$api.home.topicGoodsAddress().then(res => {
    //     if (res.code === 100) {
    //       this.topicList = res.returnValue;
    //     }
    //   });
    // },
    getArticleImages() {
      this.$api.home.topicBannerAddress().then((res) => {
        if (res.code === 100) {
          this.images1 = res.returnValue.lineFirst;
          this.images2 = res.returnValue.lineSecond;
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
@import "../../assets/styles/element.less";
.topic-select {
  width: 100%;
  box-sizing: border-box;
  /deep/ .panel {
    .panel();
    // padding: 0 0.8rem;
    .promotion_slider {
      padding: 0.1rem /* 4.8/50 */ 0rem;
      .topic-cateimg {
        width: 100%;
        box-sizing: border-box;
        padding: 0 0.16rem /* 8/50 */;
        img {
          width: 100%;
          height: 100%;
          border-radius: 0.16rem /* 8/50 */;
        }
      }
      .swiper-container {
        box-sizing: border-box;
        padding: 0 0.1rem /* 4.8/50 */;
        .swiper-slide {
          background-color: #fff;
          text-align: center;
          padding: 0.16rem /* 8/50 */ 0.06rem /* 3.2/50 */;
          border-radius: 0.06rem /* 3.2/50 */;
          box-sizing: border-box;
          width: 100%;
          height: 100%;
          a {
            display: inline-block;
            width: 100%;
            img {
              width: 100%;
              display: inline-block;
              height: 1.92rem /* 96/50 */;
              justify-content: space-around;
              justify-content: space-between;
            }
            & > p {
              color: rgb(122, 121, 121);
              font-size: 0.28rem /* 14/50 */;
              margin: 0.16rem /* 8/50 */ 0;
              line-height: 0.32rem /* 16/50 */;
              text-align: left;
              text-overflow: -o-ellipsis-lastline;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              /*! autoprefixer: off */
              -webkit-box-orient: vertical;
              /* autoprefixer: on */
            }
          }
        }
      }
    }
  }
}
.topic-goodsimg{
  margin-left: .3rem;
  width: 6.9rem;
  height: 2.9rem;
  border-radius: .2rem;
  overflow: hidden;
}
.topic-cateimg{
  width: 100% !important;
  height: 100% !important;
  >img{
     width: 100% !important;
    height: 100% !important;
  }
}

</style>

