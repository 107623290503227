<template>
    <div class="distribution">
        <div class='position-r'><img @click="a" style="height:3.2rem" src="../../assets/images/直播.png" alt=""> <div class='position-a'><h3>直播中心</h3><span>云楚新能源精选好物</span></div></div>
        <div style="margin-left:.2rem">
            <div class='position-r'><img @click="a"  style="height:1.52rem" src="../../assets/images/积分兑换.png" alt=""><div class='position-a'><h3>积分兑换</h3><span>云楚新能源惊喜多多</span></div></div>
            <div class='right-bottom'>
                <div class='position-r'><img @click="a"  style="height:1.48rem" src="../../assets/images/好物入驻.png" alt=""><div class='position-a-s'><h3>好物入驻</h3><span>申请入驻</span></div></div>
                <div style="margin-left:.2rem" class='position-r'><img style="height:1.48rem" src="../../assets/images/好物分销.png" alt=""><div class='position-a-s'><h3>好物分销</h3><span>高效多渠道</span></div></div>
            </div>
        </div>
    </div>
</template>

<style lang='less' scoped>
    .distribution{
        display: flex;
        margin-bottom: .14rem;
        padding: 0 .3rem;
    }
    .right-bottom{
        display: flex;
    }
    .position-r{
        position: relative;
    }
    .position-a{
        display: flex;
        flex-direction: column;
        justify-content: start;
        position: absolute;
        top:.42rem;
        left: .31rem;
        >h3{
            font-size: .3rem;
            font-family: PingFang SC;
            font-weight: bold;
            color: #42230A;
            line-height: .27rem;
        }
        >span{
            margin-top: .13rem;
            font-size: .24rem;
            font-family: PingFang SC;
            font-weight: 400;
            color: #7B6E64;
            line-height: .27rem;
        }
    }
     .position-a-s{
        display: flex;
        flex-direction: column;
        justify-content: start;
        position: absolute;
        top:.22rem;
        left: .21rem;
        >h3{
            font-size: .3rem;
            font-family: PingFang SC;
            font-weight: bold;
            color: #42230A;
            line-height: .27rem;
        }
        >span{
            margin-top: .13rem;
            font-size: .24rem;
            font-family: PingFang SC;
            font-weight: 400;
            color: #7B6E64;
            line-height: .27rem;
        }
    }
</style>

<script>
export default {
    data(){
        return{

        }
    },
    methods:{
        a(){
            this.$toast('敬请期待!')
            //   let appid = window.g.appid;
            //   let url = window.g.wxUrl;
            // let appid = 'wxed27930e58b472ee'
            // let url = 'http://dhmall-app-v2.keplerlab.cn'
            // let url = 'http://192.168.31.90:8082/#/index'
            // let url = 'http://dhs.keplerlab.cn/core/api/system/WxLogin_checkWx'
            // url = encodeURIComponent(url)
            // location.href = `https://open.weixin.qq.com/connect/oauth2/authorize?appid=${appid}&redirect_uri=${url}&response_type=code&scope=snsapi_base&state=1#wechat_redirect`
        }
    }
}
</script>