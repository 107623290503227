<template>
  <div class="banner">
    <div class="swiper-container banner" id="banner">
      <div class="swiper-wrapper">
        <div
          class="swiper-slide pic"
          v-for="(item, index) in images"
          :key="index"
          @click="goArticledetail(item)"
        >
          <img :src="item.imgUrl" alt />
        </div>
      </div>
      <!-- <div class="swiper-pagination"></div> -->
    </div>
  </div>
  <!-- <div class="banner">
    <div class="swiper-container banner" id="banner">
      <div class="swiper-wrapper">
        <div
          class="swiper-slide pic"
          v-for="(item, index) in images"
          :key="index"
          @click="goArticledetail(item)"
        >
          <img :src="item.image" alt />
        </div>
      </div>
      <div class="swiper-pagination"></div>
    </div>
  </div> -->
</template>

<script>
import Swiper from "../../../public/js/swiper.min.js";
import "../../../public/css/swiper.min.css";
export default {
  data() {
    return {
      images: [],
    };
  },
  methods: {
    onLoad() {
      this.$api.home
        .getBanner({ adType: "5" })
        .then((res) => {
          if (res.errno == 200) {
            this.images = res.data;

            this.$nextTick(() => {
              this.getSwiper();
            });
          }
        })
        .catch((err) => {});
    },
    // onLoad() {
    //   this.$api.home.getBanner({ title: "云楚新能源首页轮播图" })
    //     .then((res) => {
    //       if (res.code == 100) {
    //         this.images = res.returnValue;

    //         this.$nextTick(() => {
    //           this.getSwiper();
    //         });
    //       }
    //     })
    //     .catch((err) => {});
    // },
    // 初始化swiper
    getSwiper() {
      // eslint-disable-next-line no-unused-vars
      let mySwiper = new Swiper("#banner", {
        direction: "horizontal" /* 横向滑动 */,
        loop: true,
        effect: "cards",
        // pagination: {
        //   el: ".swiper-pagination",
        // },
        speed: 1400,
         observer: true, //修改swiper自己或子元素时，自动初始化swiper
        observeParents: true,
        autoplay: {
          disableOnInteraction: false,
          delay: 3500, //1秒切换一次
        },
      });
    },
    goArticledetail(item) {
      this.$router.push({
        path: `/articledetail/${item.id}`,
        query: {
          title: item.title,
          publishedTime: item.gmtUpdate,
          content: item.detail,
        },
      });
    },
  },
  mounted() {
    this.onLoad();
  },
};
</script>

<style lang="less" scoped>
.pic {
  width: 100%;
  height: 3.2rem ;
  border-radius: .2rem /* 20/100 */;

  img {
    width: 100%;
    height: 100%;
  border-radius: .2rem /* 20/100 */;

  }
}
.banner{
  box-shadow: 0px 15px 25px 0px rgba(0, 0, 0, 0.14);

}


</style>