<template>
  <div class="unique-feature dark_bg">
    <!-- <panel title="云南特色馆" class="dark_bg"> -->
      <van-swipe :autoplay="3000" indicator-color="white" :show-indicators='false'>
        <van-swipe-item
          v-for="item in imgList"
          :key="item.id"
          
        >
        <!-- @click="goSecondClass(item.categoryId, item.imgUrl,item.title)" -->
          <!-- @click="goDetail(item)" -->
          <van-image width="100%" height="160" :src="item.imgUrl" />
          <!-- <router-link :to="`/uniqueFeatureSecond/${item.id}?categoryName=${item.categoryName}`"> -->
          <!-- <router-link :to="{path: `/uniqueFeatureSecond/${item.id}`, query: {categoryName: item.title }}"> -->
          <!-- </router-link> -->
        </van-swipe-item>
      </van-swipe>
    <!-- </panel> -->
  </div>
  <!-- <div class="unique-feature dark_bg">
    <panel title="云南特色馆" class="dark_bg">
      <van-swipe :autoplay="3000" indicator-color="white">
        <van-swipe-item v-for="item in imgList" :key="item.id">
          <router-link :to="`/uniqueFeatureSecond/${item.id}?categoryName=${item.categoryName}`">
            <van-image width="100%" height="200" :src="item.imageURL" />
          </router-link>
        </van-swipe-item>
      </van-swipe>
    </panel>
  </div> -->
</template>

<script>
import Panel from "@/components/common/panel";
import { Swipe, SwipeItem, Image } from "vant";
export default {
  data() {
    return {
      imgList: [
        // { imageURL: require("./test/保山馆.jpg") },
        // { imageURL: require("./test/楚雄馆.jpg") },
        // { imageURL: require("./test/大理馆.jpg") },
        // { imageURL: require("./test/德宏馆.jpg") },
        // { imageURL: require("./test/迪庆馆.jpg") },
        // { imageURL: require("./test/红河馆.jpg") },
        // { imageURL: require("./test/昆明馆.jpg") },
        // { imageURL: require("./test/曲靖馆.jpg") },
        // { imageURL: require("./test/文山馆.jpg") },
        // { imageURL: require("./test/西双版纳馆.jpg") },
        // { imageURL: require("./test/玉溪馆.jpg") },
        // { imageURL: require("./test/昭通馆.jpg") }
      ],
    };
  },
  components: {
    Panel,
    [Swipe.name]: Swipe,
    [SwipeItem.name]: SwipeItem,
    [Image.name]: Image,
  },
  created() {
    this.getData();
  },
  methods: {
    getData() {
      this.$api.home
        .goodsCategory({
          adType: "6",
        })
        .then((res) => {
          if (res.errno === 200) {
            this.imgList = res.data;
          }
        });
    },
    // getData() {
    //   this.$api.home
    //     .goodsCategory({
    //       parentType: 15
    //     })
    //     .then(res => {
    //       if (res.code === 100) {
    //         this.imgList = res.returnValue;
    //       }
    //     });
    // },

     goSecondClass(categoryId, img,title) {
      this.$router.push(
        {
          path: `/categoryImgGoods/${categoryId}?categoryName=${title}&categoryId=${categoryId}`,
          query: {
            iconUrl: img,
          },
        }
        // `/categoryImgGoods/${categoryId}?&iconUrl=${img}`
      );
      // this.$router.push(`/secondclass/${firstId}/goods/${secondId}`);
    },
    goDetail(item) {
      //console.log("goDetail");
      this.$router.push({
        path: `/uniqueFeatureSecond/${item.id}`,
        query: {
          categoryName: item.title,
          imgUrl: item.imgUrl,
          detail: item.detail,
          VCSUrl: item.vcsUrl,
          videoURL: item.videoUrl,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
@import "../../assets/styles/element.less";
.unique-feature {
  // width: 90%;
  width: 6.9rem /* 345/50 */;
  box-sizing: border-box;
  height: 3.2rem ;
  border-radius: .2rem /* 20/100 */;
   img {
    width: 100%;
    height: 100%;
  border-radius: .2rem /* 20/100 */;

  }
   box-shadow: 0px 15px 25px 0px rgba(0, 0, 0, 0.14);
  // & /deep/ .panel {
  //   .panel();
  // }
  // /deep/ .van-swipe__track {
  //   height: 200px;
  // }
}
</style>