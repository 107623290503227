<template>
  <div class="explosion-reco">
  
    <!-- <Panel title="爆款推荐"  class="dark_bg"> -->
      <div class='header'><h3 class='header-title'>本周爆款</h3> <span class='header-more' @click='goSecondClass'>查看更多 <img style='width:.22rem' src='../../assets/images/more-title.png' > </span></div>
      <ul class='listContent'>
        <li
          class="lists"
          v-for="item in goodsList"
          :key="item.id"
          @click="goGoodsDetail(item.id, item.status)"
        >
          <div class='lists-img'>
            <!-- style="width: 100%; height: 30vw" -->
            
          <img style="width: 1.7rem; height: 1.7rem" v-lazy="item.img" alt />

          </div>
          <p
            style="
              font-size: .22rem;
              font-family: PingFang SC;
              font-weight: 400;
              color: #333333;
              line-height: .3rem;
              display: -webkit-box;
              -webkit-box-orient: vertical;
              -webkit-line-clamp: 2;
              overflow: hidden;
            "
          >
            {{ item.title }}
          </p>
          <!-- <p style="color: red; 
          margin-top:.12rem;
          font-size: .24rem;
          font-family: PingFang SC;
          font-weight: bold;
          color: var(--price-text-color);
          line-height: .3rem;">¥{{ item.showPrice }}</p> -->
          <!-- line-height: .3rem;">¥{{ item.price / 100 }}</p> -->
          <p class="showPriceStyle">¥{{ item.showPrice }}</p>
        </li>
      </ul>
    <!-- </Panel> -->
  </div>
</template>

<script>
import Panel from "@/components/common/panel";
export default {
  data() {
    return {
      goodsID:undefined,
      goodsList: [],
      goGoodsDetail: this.Base.goGoodsDetail,
    };
  },
  components: {
    Panel,
  },
  created() {
    this.getGoodsList();
  },
  methods: {
     goSecondClass() {
       console.log(this.goodsID);
      this.$router.push(
        {
          path: `/categoryImgGoods/${this.goodsID[0].id}?categoryName=${this.goodsID[0].title}&categoryId=${this.goodsID[0].id}`,
          query: {
            iconUrl: this.goodsID[0].picUrl,
          },
        }
        // `/categoryImgGoods/${categoryId}?&iconUrl=${img}`
      );
      // this.$router.push(`/secondclass/${firstId}/goods/${secondId}`);
    },
    getGoodsList() {
      this.$api.home.explosionAddress().then((res) => {
        // console.log(res,'爆品');
        if (res.errno === 200) {
          this.goodsID = res.data
          this.goodsList = res.data[0].spuDTOList;
        }
      });
    },
    // getGoodsList() {
    //   this.$api.home.explosionAddress().then(res => {
    //     if (res.code === 100) {
    //       this.goodsList = res.returnValue.data;
    //     }
    //   });
    // },
  },
};
</script>

<style lang="less" scoped>
@import "../../assets/styles/element.less";
.explosion-reco {
  // margin-top: .52rem;
  margin:0.52rem .3rem 0;
  padding: .3rem .3rem 0;
  // height: 4.8rem;
  // width: 100%;
  box-sizing: border-box;
  /deep/ .panel {
    .panel();
    ul {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      // padding: 0 0.1rem;
      li {
        margin: 1vw;
        width: 30vw;
        // height: 2.6rem /* 130/50 */;
        box-sizing: border-box;
        // padding: 0.05rem /* 3/50 */ 0 /* 8/50 */;
        font-size: 0.18rem;
        // text-align: center;
        img {
          width: 100%;
          height: 33vw;
          border-radius: 0.16rem /* 8/50 */;
        }
      }
    }
  }
  @media (prefers-color-scheme: dark) {
    & /deep/ .panel {
      h4 {
        color: #fff;
      }
    }
  }
};
.header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom:.3rem

}
.header-title{
 font-size: .32rem;
font-family: PingFang SC;
font-weight: bold;
color: #333333;
}
.header-more{
  font-size: .26rem;
  font-family: PingFang SC;
  font-weight: 400;
  color: #AEAEAE;
}
.listContent{
  display: flex;
  overflow-x: scroll;
  height: 3.3rem;
  &::-webkit-scrollbar { width: 0 !important }
 
  .lists{
    margin: 0 .15rem;
    width: 1.7rem;
    // height: 2.2rem;
    &:nth-of-type(1){
    margin-left: 0 !important;

  }
    .lists-img{
      margin-bottom: .12rem;
      overflow: hidden;
      border-radius: .1rem;
      height: 1.7rem;
      
    }
    .showPriceStyle {
      margin-top:.12rem;
      font-size: .24rem;
      font-family: PingFang SC;
      font-weight: bold;
      color: @price_text_color;
      line-height: .3rem;
    }
  }
   
}

</style>