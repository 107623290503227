<template>
  <div class="home">
    <!-- <div class="mask" v-if="false"></div> -->
    <heador class="header" />
    <!-- 主题切换 -->
    
    <!-- <banner class='banner'/> -->
    <UniqueFeature class="banner" />
    
    <categoryFirst />
    <!-- <CategoryImgList /> -->
    <!-- <div> -->

    <!-- <Distribution /> -->
    <div class="cut-off"></div>
    <div v-if="foodImg">
      <img
        style="width: 100%"
        :src="foodImg"
        @click="$router.push('/classFicationFood')"
      />
    </div>
    <topicSelect style="margin-top: 0.44rem" />
    <ExplosionReco class="explosionReco-center" />

    <Lovely name="精选" />
    <NavBar activeFlag="0" />
    <!-- 更新提示弹窗 -->

    <van-popup
      v-model="showpPolicy"
      :overlay-style="{ backgroundColor: 'rgba(0,0,0,0.3)' }"
      :close-on-click-overlay="false"
      class="popupContent"
    >
      <div class="title-h2">更新提醒</div>
      <div class="popupContent-div">
        <p class="text-p">
          <span style="color: #333" class="briefIntroduction">尊敬的用户：</span
          ><br />
          <span class="text-v">
            为了提供更优质的购物体验，系统已升级，请您尽快升级！</span
          >
          <br />
        </p>
      </div>

      <div class="confirm" v-show="toUpdateBtn">
        <van-button class="confirm-btn btns" @click="later">稍后</van-button>
        <van-button class="confirm-btn btn" @click="toUpdateIos"
          >更新</van-button
        >
      </div>
      <div class="confirm-toUpdate" v-show="toUpdate">
        <van-button
          class="confirm-btn-toUpdate btn"
          v-if="androidText"
          @click="update"
          >更新</van-button
        >
        <van-button class="confirm-btn-toUpdate btn" v-else disabled>
          更新中,请稍后...</van-button
        >
      </div>
    </van-popup>

    <div class="popup">
      <van-popup
        v-model="privacyShow"
        class="box"
        :close-on-click-overlay="false"
      >
        <div class="title">服务协议与隐私政策</div>
        <div class="content">
          <p>
            请您务必仔细阅读、充分理解“服务协议”
            和“隐私政策”各条款，包括但不限于：为
            了向你提供即时通讯，内容分享等服务，
            我们需要收集你的设备信息，操作日志等
            个人信息。你可以在设置中查看，变更， 删除个人信息并管理你的授权。
          </p>
          <p>
            你可阅读<span class="agree" @click="goAgree()">《服务协议》</span
            >和<span class="agree" @click="goPrivacyPolicy()"
              >《隐私政策》</span
            >
            了解详细信息。如您同意，请点击“同意”开 始接受我们的服务。
          </p>
        </div>
        <div class="bottom">
          <div class="btn" @click="cancel()">暂不使用</div>
          <div class="allow btn" @click="privacyConsent()">同意</div>
        </div>
      </van-popup>
    </div>
  </div>
</template>

<script>
import heador from "./header";
import categoryFirst from "./categoryFirst";
import banner from "./banner";
import CategoryImgList from "./categoryImgList";
import UniqueFeature from "./uniqueFeature";
import ExplosionReco from "./ExplosionReco";
import topicSelect from "./topicSelect";
import Lovely from "./lovely";
import Distribution from "./distribution";
Distribution;
import { CellGroup } from "vant";
export default {
  components: {
    heador,
    categoryFirst,
    banner,
    CategoryImgList,
    UniqueFeature,
    ExplosionReco,
    topicSelect,
    Lovely,
    Distribution,
  },
  data() {
    return {
      // imgShow: false,
      foodImg: "",
      // mask:true,
      showpPolicy: false,
      toUpdateBtn: true, //ios更新按钮
      toUpdate: false, //Android更新按钮
      url: undefined,
      fileName: undefined,
      privacyShow: false,
      androidText: true,
      
    };
  },

  created() {
    if (!localStorage.getItem("appid")) {
      this.getConfigAppId();
    }
      // this.getShareUserId()
    // if (!localStorage.privacy) {
    //   this.privacyShow = true;
    // }
    // if (this.showpPolicy) {
    //   this.privacyShow = false;
    // }

    this.logcode();
    this.getFoodImg();
    window.checkVersionJs = this.checkVersionJs;
    window.exitApp = this.exitApp;
    window.checkUpdate = this.checkUpdate;

    this.getAppVersion();
    this.judge();
    // this.masks()
    // window.cmd006 = this.cmd006;
    // this.mask = localStorage.getItem("showpPolicy")=='false'?  false: true;
    // console.log("mask",typeof this.mask);
  },
  methods: {
    
    getConfigAppId() {
      this.$api.login
        .getConfigLogoUrl({
          k: "APPID",
        })
        .then((res) => {
          // 成功
          if (res.errno === 200) {
             
            localStorage.setItem("appid", res.data.valueWorth);
          } else {
            this.$toast(res.errmsg);
          }
        });
    },
    getShareUserId() {
      let shareUserId = window.location.href.split("_")[1];
      if (shareUserId) {
        localStorage.setItem("shareUserId", shareUserId);
      }
    },

    privacyConsent() {
      this.privacyShow = false;
      localStorage.privacy = true;
    },
    // code
    logcode() {
      // this.$toast({
      //   message: location.href + 1,
      //   duration: 150000,
      // });
      // if (!sessionStorage.openId) {
      let url = location.href;

      // this.$toast({
      //   message: url + 2,
      //   duration: 150000,
      // });
      if (url.indexOf("openid") != -1) {
        // console.log(url);
        let text = url.split("?openid=")[1];
        let text1 = text.split("#")[0];
        let openId = text1;
        // console.log(openId);
        // this.$toast(openId);
        // alert(openId);
        sessionStorage.openId = openId ? openId : sessionStorage.openId;
        localStorage.removeItem("storeId");
      }
      // }
    },
    getFoodImg() {
      // console.log(321);
      // if (localStorage.token) {
      //   this.imgShow = true;
      // } else {
      //   this.imgShow = false;
      // }
      this.$api.home.getFoodCategory().then((res) => {
        if (res.data) this.foodImg = res.data.picUrl;
      });
    },
    // 判断ios，Android 显示的按钮
    judge() {
      const platform = this.Base.deviceEnvir();
      // console.log(platform,"platform");
      if (platform == "ANDROID") {
        this.toUpdate = true;
        this.toUpdateBtn = false;
      } else if (platform == "IOS") {
        this.toUpdate = false;
        this.toUpdateBtn = true;
      }
    },
    // app Android下载
    update() {
      this.androidText = false;
      // try{
      // let routeUrl = this.url;
      // console.log(routeUrl);
      // window.location.href = routeUrl;
      window.android.invokeAndroid(`cmd006,${this.url},${this.fileName}`);
      // console.log("测试");
      // }
      // catch(err){
      //   console.log("发生异常");
      // }
    },

    // ios去更新
    toUpdateIos() {
      window.webkit.messageHandlers.notificationUpdate.postMessage("");
    },

    //判断微信环境内直接删除遮罩层
    // masks(){
    //   let wx = this.is_weixn()

    //   if(wx){
    //     this.masks = false
    //   }
    //    if (this.Base.deviceEnvir() == "PC" ) {
    //     this.masks = false

    //    }
    // },

    // is_weixn() {
    //   var ua = navigator.userAgent.toLowerCase();
    //   if (ua.match(/MicroMessenger/i) == "micromessenger") {
    //     return true;
    //   } else {
    //     return false;
    //   }
    // },
    // 主动调android拿首次版本号
    getAppVersion() {
      if (this.Base.deviceEnvir() == "IOS") {
        return;
      } else {
        let getApp_version = window.revisionInterface.getAppVersion();
        // console.log("调用",getApp_version);
        let arr = JSON.parse(getApp_version);

        const versionObj = arr.version ? arr.version : "1";
        const platform = this.Base.deviceEnvir();
        let editionObj = {};
        editionObj = {
          ...editionObj,
          systemType: platform,
          version: versionObj,
        };
        // console.log(editionObj);
        this.$api.login.getAppVersion(editionObj).then((res) => {
          // console.log(res.data);
          // this.$toast(res.data);
          // this.mask = res.data.isVersion =='false'? false : true;
          this.showpPolicy = res.data.isVersion == "false" ? false : true;
          this.url = res.data.url;
          this.fileName = res.data.fileName;
          localStorage.setItem("showpPolicy", this.isVersion);
          if (this.showpPolicy) {
            this.privacyShow = false;
          }
        });
      }
    },

    // 判断版本ios Android公用方法
    checkVersionJs(version) {
      // console.log(version);

      let arr;
      if (this.Base.deviceEnvir() == "IOS") {
        arr = JSON.parse(version);
      } else {
        arr = version;
      }
      // console.log(arr);
      1; // return;
      const versionObj = arr.version ? arr.version : "1";
      const platform = this.Base.deviceEnvir();
      // console.log("platform1111",platform);
      let editionObj = {};
      editionObj = {
        ...editionObj,
        systemType: platform,
        version: versionObj,
      };
      // console.log(editionObj);
      this.$api.login.getAppVersion(editionObj).then((res) => {
        // console.log(res.data.isVersion,"111111");
        // this.$toast(res.data);
        // this.mask = res.data.isVersion =='false'? false : true;
        // console.log(!!res.data.isVersion);
        this.showpPolicy = res.data.isVersion == "false" ? false : true;
        this.url = res.data.url;

        // console.log( this.mask,this.showpPolicy);
        if (this.showpPolicy) {
          this.privacyShow = false;
        }

        this.fileName = res.data.fileName;

        localStorage.setItem("showpPolicy", this.isVersion);
      });
    },
    cancel() {
      if (this.Base.deviceEnvir() == "IOS") {
        window.webkit.messageHandlers.exitApp.postMessage("1");
      } else if (this.Base.deviceEnvir() == "ANDROID") {
        window.revisionInterface.exitApp();
      }
    },
    goAgree() {
      this.$router.push("/agree");
    },
    goPrivacyPolicy() {
      this.$router.push("/privacypolicy");
    },

    // 原生退出app方法
    exitApp() {
      window.webkit.messageHandlers.exitApp.postMessage("1");
    },
    // 点击稍后退出app
    later() {
      this.exitApp();
      // this.showpPolicy = false;
    },
  },
};
</script>

<style lang="less" scoped>
.home {
  height: 100%;
  box-sizing: border-box;
  background-color: #fff;
  padding-bottom: 1.06rem /* 52.8/50 */;
  position: relative;
  overflow: hidden;
}

// 弹窗
.popupContent {
  box-sizing: border-box;
  width: 5.4rem /* 270/50 */;
  // height: 8rem;
  max-height: 8rem /* 400/50 */;
  border-radius: 0.2rem /* 10/50 */;
  display: flex;
  flex-direction: column;
  align-items: center;
  // justify-content: space-between;

  .popupContent-div {
    // margin-bottom: .68rem /* 34/50 */;
    padding: 0rem /* 23/50 */ 0.44rem /* 22/50 */ 0.4rem /* 20/50 */;
    overflow: scroll;
  }
  p {
    // text-indent: 2em;
    font-size: 0.28rem /* 14/50 */;
    font-family: PingFang SC;
    color: #333333;
  }
  .confirm {
    // margin-top: .4rem /* 20/50 */;
    width: 100%;
    font-size: 0.32rem /* 16/50 */;
    font-weight: 500;
    border-top: 0.02rem /* 1/50 */ solid #efefef;
    // position:fixed;
    left: 0;
    bottom: 0;
    // border: 0 .1rem /* 5/50 */ .1rem /* 5/50 */ 0;
    .confirm-btn {
      font-family: PingFang SC;
      height: 0.88rem /* 44/50 */;
      line-height: 0.88rem /* 44/50 */;
      width: 50%;
      border-left: none;
      border-top: none;
    }
    .btn {
      color: #bb1d1f;
      border-bottom-right-radius: 0.2rem;
      border-bottom: 0;
    }
    .btns {
      color: #7e7e7e;
      border-bottom-left-radius: 0.2rem;
      border-bottom: 0;
    }
  }
}
.title-h2 {
  padding-top: 0.4rem /* 20/50 */;
  padding-bottom: 0.24rem /* 12/50 */;
  text-align: center;
  font-size: 0.32rem /* 16/50 */;
  font-weight: 700;
}
.confirm-toUpdate {
  // margin-top: .4rem /* 20/50 */;
  width: 100%;
  font-size: 0.32rem /* 16/50 */;
  font-weight: 500;
  border-top: 0.02rem /* 1/50 */ solid #efefef;
  // position:fixed;
  left: 0;
  bottom: 0;
  .confirm-btn-toUpdate {
    font-family: PingFang SC;
    height: 0.88rem /* 44/50 */;
    line-height: 0.88rem /* 44/50 */;
    width: 100%;
    border-top: none;
    .btn {
      color: #bb1d1f;
    }
  }
}
.mask {
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  background-color: #33333300;
}
.text-v {
  display: inline-block;
  margin-top: 0.05rem /* 20/50 */;
  line-height: 1.5;
  // text-indent: 2em;
}
.box {
  border-radius: 0.2rem;
  width: 80%;
  .title {
    font-size: 0.27rem;
    font-weight: 550;
    text-align: center;
    margin-bottom: 0.25rem;
    background-color: #d03c3c;
    padding: 0.3rem 0.6rem;
    color: #fff;
  }
  .content {
    padding: 0.2rem 0.4rem;
    p {
      color: #333;
      font-size: 0.27rem;
      line-height: 1.5;
      margin-bottom: 0.2rem;
      // text-indent: 2em;
      .agree {
        color: #d03c3c;
        font-weight: 550;
      }
    }
  }
  .btn {
    width: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #333;
    padding: 0.3rem 0;
    font-size: 0.3rem;
    font-weight: 550;
  }
  .allow {
    color: #d03c3c;
    border-left: 1px solid #f4f4f4;
  }
}
.bottom {
  display: flex;
  align-items: center;
  border-top: 1px solid #f4f4f4;
}
// .header{
//   margin-bottom: 1.08rem /* 54/50 */;
// }
.banner {
  // padding-top: 1rem /* 50/50 */;
  margin: 1.1rem 0.3rem 0.5rem /* 30/50 */;
  border-radius: 0.2rem;
  overflow: hidden;
}
.cut-off {
  width: 100%;
  height: 0.2rem;

  background: #f4f4f4;
}
.explosionReco-center {
  background: #ffffff;
  box-shadow: 0px 0px 0.2rem 0px rgba(0, 0, 0, 0.1);
  border-radius: 0.2rem;
}
</style>