<template>
  <div class="header" :class="{ 'scoll-fixed': isfixed }">
    <section>
      <div class="input-text" @click="goSearch">
        商品名称
        <span class="iconfont icon-sousuo"></span>
      </div>
      <!-- <p>
         <span class="iconfont iconfonts icon-xiaoxi" @click="show"></span>
      </p> -->
      <!-- <router-link to="/customerChat">消息</router-link> -->
<!--         <span style="font-size: 0.2rem" @click="show">消息</span>-->
    </section>
  </div>
</template>

<script>
export default {
  data() {
    return {
      isfixed: false,
    };
  },
  mounted() {
    // 获取元素距离最初距离顶部的位置距离
    this.offsetTop = document.querySelector(".header").offsetTop;
    // 开启滚动监听
    window.addEventListener("scroll", this.handleScroll);
  },
  methods: {
    // 滚动监听  滚动触发的效果写在这里
    handleScroll() {
      // 获取滚动条距离顶部的位置
      var scrollTop =
        window.pageYOffset ||
        document.documentElement.scrollTop ||
        document.body.scrollTop;
      // 滚动条距离与元素顶部距离进行比较
      if (scrollTop > this.offsetTop) {
        this.isfixed = true;
      } else {
        this.isfixed = false;
      }
    },
    // 跳转到搜索页面
    goSearch() {
      this.$router.push({ name: "search" });
    },
    show() {
      this.$router.push("/customerChat")
     
    },
  },

  destroyed() {
    window.removeEventListener("scroll", this.handleScroll); // 离开页面 关闭监听 不然会报错
  },
};
</script>
<style lang="less" scoped>
@red: #d84b41;
.header {
  width: 100%;
  box-sizing: border-box;
  position: absolute;
  top: 0px;
  left: 0;
  z-index: 999;
  & > section {
    padding: 0.15rem 0.3rem;
    background-color: rgba(0, 0, 0, 0);
    display: flex;
    height: 1rem;
    box-sizing: border-box;
    align-items: center;
    font-size: 0.27rem;
    justify-content: space-between;
    & > div {
      // width: 86%;
      width: 100%;
      height: .7rem !important;
      padding-left: 0.3rem;
      // border-radius: 0.3rem;
      // background-color: #fff;
      position: relative;
      height: 0.5rem;
      border: 1px solid #fff;
      line-height: 0.5rem;
      color: rgb(155, 154, 154);
      // border: 1px solid #fff;
      border-radius: .2rem;
      
      background: #F6F6F9;
      span {
        position: absolute;
        top: 1px;
        // color: #c83f3f;
        color: #bfbfbf;
        font-weight: bold;
        right: .33rem;
        font-size: 0.4rem;
      }
    }
    & > p {
      text-align: center;
      color: #fff;
      span {
        display: block;
        font-size: 0.5rem;
        color: #333333;
        // margin-right: .35rem;
      }
      a {
        transition: all linear 0.5s;
        color: #fff;
      }
    }
  }
  &.scoll-fixed {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    background-color: #fff;
    box-shadow: 0 1px 3px #dddbdb;
    transition: all linear 0.5s;
    & > section {
      & > div {
        transition: all linear 0.5s;
        border: 1px solid #fff;
      }
      & > p {
        span {
          transition: all linear 0.5s;
          color: #333333;
        }
        a {
          transition: all linear 0.5s;
          color: #9b9a9a;
        }
      }
    }
  }
}
.input-text{
  line-height: .7rem !important;
}

</style>
