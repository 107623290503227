<template>
  <ul class="categroy-img-list">
    <li
      v-for="item in categoryImgList"
      :key="item.id"
      @click="goGoodsList(item)"
    >
      <img v-lazy="item.picUrl" alt />
    </li>
    <li
      class="city"
      v-for="item in specialList"
      :key="item.id"
      @click="goGoodsList(item)"
    >
      <img v-lazy="item.picUrl" alt />
    </li>
  </ul>
  <!-- <ul class="categroy-img-list">
    <li
      v-for="item in categoryImgList"
      :key="item.id"
      @click="goGoodsList(item)"
    >
      <img v-lazy="item.imageURL" alt />
    </li>
    <li class="city" @click="goGoodsList(item)">
      <img v-lazy="city.imageURL" alt />
    </li>
    <li
      class="city"
      v-for="item in specialList"
      :key="item.id"
      @click="goGoodsList(item)"
    >
      <img v-lazy="item.imageURL" alt />
    </li>
  </ul> -->
</template>

<script>
export default {
  data() {
    return {
      categoryImgList: [],
      specialList: [],
      city: {},
    };
  },
  created() {
    this.getImgCategroy();
  },
  methods: {
    getImgCategroy() {
      this.$api.home.getIndividualImgCategroy({ type: "4" }).then((res) => {
        //console.log("getImgCategroy", res);
        if (res.errno === 200) {
          // let list = res.data;
          // this.city = list.pop();
          // this.categoryImgList = list;

          res.data.map((item) => {
            if (
              item.title.includes("同城") ||
              item.title.includes("中秋") ||
              item.title.includes("国庆") ||
              item.title.includes("新品推荐")
            ) {
              this.specialList.push(item);
            } else {
              this.categoryImgList.push(item);
            }
          });
        }
      });
    },
    // getImgCategroy() {
    //   this.$api.home.getIndividualImgCategroy().then((res) => {
    //     console.log('getImgCategroy*****',res);
    //     if (res.code === 100) {
    //       let list = res.returnValue;
    //       this.city = list.pop();
    //       this.categoryImgList = list;
    //     }
    //   });
    // },
    // getImgCategroy() {
    //   this.$api.home.getIndividualImgCategroy({}).then((res) => {
    //     if (res.code === 100) {
    //       res.returnValue.map((item) => {
    //         if (
    //           item.categoryName.includes("同城") ||
    //           item.categoryName.includes("中秋") ||
    //           item.categoryName.includes("国庆") ||
    //           item.categoryName.includes("新品推荐")
    //         ) {
    //           this.specialList.push(item);
    //         } else {
    //           this.categoryImgList.push(item);
    //         }
    //       });
    //     }
    //   });
    // },
    // 进商品列表
    goGoodsList(item) {
      const { id, title, iconUrl } = item;
      let colorType = -1;
      if (title.includes("中秋") || title.includes("国庆")) {
        colorType = 1;
      }
      if (title.includes("新品推荐")) {
        colorType = 1;
      }
      if (title == "积分兑换") {
        this.$toast(`${title},敬请期待`);
        return false;
      }
      // this.$router.push(
      //   `/categoryImgGoods/${id}?categoryName=${title}&colorType=${colorType}&iconUrl=${iconUrl}`
      // );
      this.$router.push({
        path: `/categoryImgGoods/${id}?categoryName=${title}&colorType=${colorType}`,
        query: {
          iconUrl: iconUrl,
        },
      });
    },
  },
};
</script>

<style lang="less" scoped>
.categroy-img-list {
  width: 100%;
  padding: 0.16rem /* 8/50 */;
  box-sizing: border-box;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  li {
    width: 50%;
    height: 1.92rem /* 96/50 */;
    padding: 0.06rem /* 3.2/50 */;
    box-sizing: border-box;
    img {
      width: 100%;
      height: 100%;
      border-radius: 0.13rem /* 6.4/50 */;
    }
  }
  .city {
    width: 100%;
  }
}
</style>