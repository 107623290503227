<template>
    <!-- <van-tabs
      v-model="firstId"
      animated
      :swipe-threshold="8"
      color="#d84b41"
      title-active-color="#fff"
      title-inactive-color="#333333"
      background="rgba(0,0,0,0)"
      line-width
      :border="false"
      :ellipsis="false"
      @click="clickActive"
    >
      <van-tab
        v-for="item in categoryList"
        :title="item.title"
        :key="item.id"
        :name="item.id"
      ></van-tab>
      </van-tabs> -->



   <!-- swiper -->
    <!-- <div class="swiper-container">
        <div class="swiper-wrapper">
           

      <div class="swiper-slide categoryFirst">

        <div v-for='v in categoryList' :key='v.id' class="center" @click="goSecondclass(v)">
        <div><img style="width:.91rem" :src="v.picUrl" ></div>
        <div>{{v.title}}</div>
      </div>

      </div>

       <div class="swiper-slide categoryFirst">

        <div v-for='v in categoryListTWO' :key='v.id' class="center" @click="goSecondclass(v)">
        <div><img style="width:.91rem" :src="v.picUrl" ></div>
        <div>{{v.title}}</div>
      </div>

      </div>


      </div>
        
        <div class="swiper-pagination" slot="pagination"></div>
        
    </div> -->

    <van-swipe class="swiper-container my-swipe"  indicator-color="white">
      <van-swipe-item class="first-list">
         <div v-for='v in categoryList' :key='v.id' :style="{width: flexItemWidth}" class="center" @click="goSecondclass(v)">
        <div><img style="width:.91rem" :src="v.picUrl" ></div>
        <div>{{v.title}}</div>
      </div>
      </van-swipe-item>
      <van-swipe-item v-if="categoryListTWO.length" class="two-list">
        <div v-for='v in categoryListTWO' :key='v.id' :style="{width: flexItemWidth}" class="center" @click="goSecondclass(v)">
        <div><img style="width:.91rem" :src="v.picUrl" ></div>
        <div>{{v.title}}</div>
      </div>
      </van-swipe-item>
      
    </van-swipe>


    
     

      <!-- <div class="categoryFirst">

        <div v-for='v in categoryList' :key='v.id' class="center">
        <div><img style="width:.9rem" :src="v.picUrl" ></div>
        <div>{{v.title}}</div>
      </div>
      
      </div> -->


   
      
      
     
    
  
</template>

<script>
import Swiper from "../../../public/js/swiper.min.js";
import "../../../public/css/swiper.min.css";


export default {
  data() {
    return {
      firstId: 0,
      flexItemWidth: 100 / 4 + "%",
      // categoryList: [
      //   {
      //     name: "休闲零食",
      //     imgageURL: "",
      //     sub: [
      //       {
      //         name: "零食",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "煎饼果子",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "鸭脖",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "蛋糕",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "面包",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "糖果",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "零食",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "坚果",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "鸭脖",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "蛋糕",
      //         imgageURL: ""
      //       }
      //     ]
      //   },
      //   {
      //     name: "家居",
      //     imgageURL: "",
      //     sub: [
      //       {
      //         name: "收纳柜",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "雨伞",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "花艺",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "拉杆箱",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "经营",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "教育考试",
      //         imgageURL: ""
      //       }
      //     ]
      //   },
      //   {
      //     name: "电器",
      //     imgageURL: "",
      //     sub: [
      //       {
      //         name: "电水壶",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "煮蛋器",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "榨汁机",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "蛋糕",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "电饭煲",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "平底锅",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "雨伞",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "花艺",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "拉杆箱",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "经营",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "教育考试",
      //         imgageURL: ""
      //       }
      //     ]
      //   },
      //   {
      //     name: "衣物清洁",
      //     imgageURL: "",
      //     sub: [
      //       {
      //         name: "蓝月亮",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "立白",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "羽绒清洗剂",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "婴儿洗衣液",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "衣物除菌",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "柔顺剂",
      //         imgageURL: ""
      //       }
      //     ]
      //   },
      //   {
      //     name: "美妆",
      //     imgageURL: "",
      //     sub: [
      //       {
      //         name: "肌底液",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "唇膜",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "润唇膏",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "爽肤水/化妆术",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "防晒",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "蜜粉散粉",
      //         imgageURL: ""
      //       }
      //     ]
      //   },
      //   {
      //     name: "内衣袜子",
      //     imgageURL: "",
      //     sub: [
      //       {
      //         name: "男士睡衣",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "女士睡衣",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "睡裙",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "光面无痕",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "大码文胸",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "男士内裤",
      //         imgageURL: ""
      //       }
      //     ]
      //   },
      //   {
      //     name: "汽车用品",
      //     imgageURL: "",
      //     sub: [
      //       {
      //         name: "车钥匙扣",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "玻璃水",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "清洁剂",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "车载水箱",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "对讲电台",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "挂件摆件",
      //         imgageURL: ""
      //       }
      //     ]
      //   },
      //   {
      //     name: "休闲零食",
      //     imgageURL: "",
      //     sub: [
      //       {
      //         name: "零食",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "坚果",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "鸭脖",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "蛋糕",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "面包",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "糖果",
      //         imgageURL: ""
      //       }
      //     ]
      //   },
      //   {
      //     name: "家具日用",
      //     imgageURL: "",
      //     sub: [
      //       {
      //         name: "收纳柜",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "雨伞",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "花艺",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "拉杆箱",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "经营",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "教育考试",
      //         imgageURL: ""
      //       }
      //     ]
      //   },
      //   {
      //     name: "生活电器",
      //     imgageURL: "",
      //     sub: [
      //       {
      //         name: "电水壶",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "煮蛋器",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "榨汁机",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "蛋糕",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "电饭煲",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "平底锅",
      //         imgageURL: ""
      //       }
      //     ]
      //   },
      //   {
      //     name: "衣物清洁",
      //     imgageURL: "",
      //     sub: [
      //       {
      //         name: "蓝月亮",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "立白",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "羽绒清洗剂",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "婴儿洗衣液",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "衣物除菌",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "柔顺剂",
      //         imgageURL: ""
      //       }
      //     ]
      //   },
      //   {
      //     name: "美妆护肤",
      //     imgageURL: "",
      //     sub: [
      //       {
      //         name: "肌底液",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "唇膜",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "润唇膏",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "爽肤水/化妆术",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "防晒",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "蜜粉散粉",
      //         imgageURL: ""
      //       }
      //     ]
      //   },
      //   {
      //     name: "内衣袜子",
      //     imgageURL: "",
      //     sub: [
      //       {
      //         name: "男士睡衣",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "女士睡衣",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "睡裙",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "光面无痕",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "大码文胸",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "男士内裤",
      //         imgageURL: ""
      //       }
      //     ]
      //   },
      //   {
      //     name: "汽车用品",
      //     imgageURL: "",
      //     sub: [
      //       {
      //         name: "车钥匙扣",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "玻璃水",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "清洁剂",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "车载水箱",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "对讲电台",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "挂件摆件",
      //         imgageURL: ""
      //       }
      //     ]
      //   },
      //   {
      //     name: "休闲零食",
      //     imgageURL: "",
      //     sub: [
      //       {
      //         name: "零食",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "坚果",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "鸭脖",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "蛋糕",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "面包",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "糖果",
      //         imgageURL: ""
      //       }
      //     ]
      //   },
      //   {
      //     name: "家具日用",
      //     imgageURL: "",
      //     sub: [
      //       {
      //         name: "收纳柜",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "雨伞",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "花艺",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "拉杆箱",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "经营",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "教育考试",
      //         imgageURL: ""
      //       }
      //     ]
      //   },
      //   {
      //     name: "生活电器",
      //     imgageURL: "",
      //     sub: [
      //       {
      //         name: "电水壶",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "煮蛋器",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "榨汁机",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "蛋糕",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "电饭煲",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "平底锅",
      //         imgageURL: ""
      //       }
      //     ]
      //   },
      //   {
      //     name: "衣物清洁",
      //     imgageURL: "",
      //     sub: [
      //       {
      //         name: "蓝月亮",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "立白",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "羽绒清洗剂",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "婴儿洗衣液",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "衣物除菌",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "柔顺剂",
      //         imgageURL: ""
      //       }
      //     ]
      //   },
      //   {
      //     name: "美妆护肤",
      //     imgageURL: "",
      //     sub: [
      //       {
      //         name: "肌底液",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "唇膜",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "润唇膏",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "爽肤水/化妆术",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "防晒",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "蜜粉散粉",
      //         imgageURL: ""
      //       }
      //     ]
      //   },
      //   {
      //     name: "内衣袜子",
      //     imgageURL: "",
      //     sub: [
      //       {
      //         name: "男士睡衣",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "女士睡衣",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "睡裙",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "光面无痕",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "大码文胸",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "男士内裤",
      //         imgageURL: ""
      //       }
      //     ]
      //   },
      //   {
      //     name: "汽车用品",
      //     imgageURL: "",
      //     sub: [
      //       {
      //         name: "车钥匙扣",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "玻璃水",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "清洁剂",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "车载水箱",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "对讲电台",
      //         imgageURL: ""
      //       },
      //       {
      //         name: "挂件摆件",
      //         imgageURL: ""
      //       }
      //     ]
      //   }
      // ],
      categoryList: [],
      categoryListTWO:[],
    };
  },
  methods: {

     getSwiper() {
      //  console.log(9879);
     var mySwiper = new Swiper('.swiper-container', {
                    //direction: 'vertical', 垂直切换选项
                    // loop: false, // 开启循环模式选项
                    // pagination: '.swiper-pagination',
    //                  pagination: {
		// 	el: '.swiper-pagination',
		// },

                   
                })
            },


    
    
    getCategory() {
      this.$api.home.getGoodsCategoryAll({ type: "1" }).then((res) => {
        if (res.errno === 200) {
          this.categoryList = res.data;
          this.categoryList = [
            // { title: "首页", id: "home", sub: [] },
            ...this.categoryList,
          ];
          if(this.categoryList.length > 10){
            this.categoryListTWO=  this.categoryList.slice(10)
            this.categoryList.splice(10)
          }
          
          // console.log(this.categoryListTWO);

          //console.log("categoryList", this.categoryList);
        }
      });
    },
    // getCategory() {
    //   this.$api.home.getGoodsCategoryAll({}).then(res => {
    //     console.log('getGoodsCategoryAll*******',res);
    //     if (res.code === 100) {
    //       this.categoryList = res.returnValue;
    //       this.categoryList = [
    //         { name: "首页", id: "home", sub: [] },
    //         ...this.categoryList
    //       ];
    //     }
    //   });
    // },
    // 操作一级分类
    clickActive(firstId) {
      console.log("firstId", firstId);
      if (firstId === "home") {
        return false;
      }
      this.categoryList.forEach((item) => {
        if (firstId === item.id) {
          // console.log(item, item);
          let secondId = item.childrenList[0].id;
          this.$router.push(`/secondclass/${firstId}/goods/${secondId}`);
          return false;
        }
      });
    },
    goSecondclass(row){
      let firstId = row.id
      let arr = [
        ...this.categoryList,
        ...this.categoryListTWO
      ]
       arr.forEach((item) => {
        if (firstId === item.id) {
          // console.log(item, item);
          if(!item.childrenList){
            this.$toast('当前分类暂无商品')
            return
          }
          let secondId = item.childrenList[0].id;
          this.$router.push(`/secondclass/${firstId}/goods/${secondId}`);
          return false;
        }
      });
    }
  },
  created() {
    this.getCategory();

  },
  mounted(){
    this.getSwiper();

  }
};
</script>

<style lang="less" scoped>
.categoryFirst {
  display: flex;
  padding: 0 .25rem;
  justify-content: start;
  // justify-content: space-around;
  align-content: flex-start;
  flex-wrap: wrap;
  font-size: .26rem;
  width: 100%;
  box-sizing: border-box;
  // position: absolute;
  // top: 4.5rem;
  // left: 0;
  z-index: 999;
  /deep/ .van-tab--active {
    font-size: 0.36rem /* 18/50 */;
  }
  /deep/.van-tabs__line {
    display: none;
  }
}
.center{
display: flex;
flex-direction: column;
align-items: center;
// margin:  .24rem ;
  font-size: .26rem;
font-family: PingFang SC;
font-weight: 500;
color: #333333;
line-height: .49rem;
justify-content: space-between;
margin: .2rem /* 10/50 */ 0;
  box-sizing: border-box;
}
.swiper-container{
  // height: 4.8rem;
  // background-color:red;
}
 /deep/ .swiper-pagination-bullet-active{
  color: #FF6468;
  width: .40rem;
height: .12rem;
background: #FF6468;
box-shadow: 0px 0px .1rem 0px rgba(55, 100, 255, 0.1);
border-radius: .6rem;
}
.my-swipe .van-swipe-item {
    // color: red;
    // font-size: 20px;
    // line-height: 150px;
    // text-align: center;
    // padding:   0 .25rem;
    padding: 0 0.25rem .5rem /* 25/50 */;
    box-sizing: border-box;
    // background-color: #39a9ed;

}
  .first-list,
  .two-list{
    width: 100%;
    display: flex;
    align-content: flex-start;

    flex-wrap: wrap;
  }

  /deep/ .van-swipe__indicators{
    // bottom: .6rem;
    bottom: .3rem /* 15/50 */;
  }
/deep/  .van-swipe__indicator{
  background-color: #AEAEAE !important;
  opacity: 0.6 !important;
}
 /deep/ .van-swipe__indicator--active{
    color: #FF6468;
  opacity: 1 !important;

  width: .40rem;
  height: .12rem;
  background-color: #FF6468 !important;
  box-shadow: 0px 0px .1rem 0px rgba(55, 100, 255, 0.1);
  border-radius: .6rem;
  }

</style>